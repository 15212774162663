"use client";

import React, { useState, useEffect, useRef } from 'react';
import Image from 'next/image';
import Link from "next/link";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from "@/components/ui/button";
import { rubik } from "@/lib/fonts";
import { twMerge } from "@/lib/utils";
import './VerticalCarousel.css';
import WordAnimation from '../wordAnimation';

export default function Component() {
  const [animationState, setAnimationState] = useState('initial');
  const [logoOpacity, setLogoOpacity] = useState(0);
  const [spinCount, setSpinCount] = useState(0);
  const [cardAnimationState, setCardAnimationState] = useState({
    fans: false,
    hosts: false,
  });
  const [isScrolled, setIsScrolled] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);

    const spinInterval = setInterval(() => {
      setSpinCount((prevCount) => {
        if (prevCount >= 3) {
          clearInterval(spinInterval);
          return 3;
        }
        return prevCount + 1;
      });
    }, 500);

    const timer1 = setTimeout(() => setAnimationState('logoMoved'), 2000);
    const timer2 = setTimeout(() => {
      setAnimationState('revealed');
      const fadeInterval = setInterval(() => {
        setLogoOpacity((prevOpacity) => {
          if (prevOpacity >= 1) {
            clearInterval(fadeInterval);
            return 1;
          }
          return prevOpacity + 0.01;
        });
      }, 20);
    }, 3000);
    const timer3 = setTimeout(() => setCardAnimationState(prev => ({ ...prev, fans: true })), 3500);
    const timer4 = setTimeout(() => setCardAnimationState(prev => ({ ...prev, hosts: true })), 4100);

    const handleScroll = () => {
      if (contentRef.current) {
        const scrollPosition = window.scrollY;
        const contentHeight = contentRef.current.offsetHeight;
        setIsScrolled(scrollPosition > contentHeight * 0.5);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearInterval(spinInterval);
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  return (
    <div className={twMerge("relative w-full min-h-screen", rubik.className)} ref={contentRef}>
      <div className={`fixed z-30 transition-all duration-500 ease-in-out ${
        animationState === 'initial' 
          ? 'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-150' 
          : isMobile ? 'top-safe-top left-4 scale-100' : 'md:top-24 md:left-24 top-8 left-8 scale-100'
      }`}>
        <div className={`relative transition-transform duration-500 ${spinCount < 3 ? 'animate-spin' : ''}`}>
          <Image
            src="/greenLogo.png"
            alt="Tick3t Tree Green Logo"
            width={100}
            height={100}
            className="w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32"
          />
          <Image
            src="/ttWhite.png"
            alt="Tick3t Tree White Logo"
            width={100}
            height={100}
            className="absolute top-0 left-0 w-16 h-16 md:w-24 md:h-24 lg:w-32 lg:h-32 transition-opacity duration-1000"
            style={{ opacity: logoOpacity }}
          />
        </div>
      </div>

      <div className="min-h-screen flex flex-col justify-between md:flex-row pt-safe-top pb-4">
        <div className={`flex-1 flex flex-col justify-center px-4 md:px-5 sm:max-w-[90%] md:max-w-[80%] lg:max-w-[80%] mx-auto transition-opacity duration-1000 ${animationState === 'revealed' ? 'opacity-100' : 'opacity-0'}`}>
          <div className="flex flex-col justify-center text-left w-full md:w-3/5 mt-16 md:mt-0 mb-4 md:mb-0">
            <div className="flex flex-col md:flex-row items-center mb-2 md:mb-4">
              <div className="w-full md:ml-20 text-2xl sm:text-3xl lg:text-8xl font-black uppercase italic text-white text-center md:text-left">
                <WordAnimation />
              </div>
            </div>
            <div className="w-full sm:w-3/4 md:flex md:ml-2 mt-2 md:mt-0">
              <div className="text-[#f5f5f5] py-0.5 text-center md:text-left text-xs md:text-base">
                Ticket Tree revolutionizes the ticketing experience by offering innovative, blockchain solutions that connect fans and events seamlessly. Unlock new possibilities for engagement and revenue with our cutting-edge platform.
              </div>
            </div>
          </div>

          {/* Mobile cards */}
          <div className={`md:hidden mt-4 transition-all duration-1000 ${
            animationState === 'revealed' ? 'opacity-100' : 'opacity-0'
          }`}>
            <div className="flex flex-col gap-2">
              <Link href="/fan/explore" passHref className={`w-full transition-all duration-500 ease-in-out ${cardAnimationState.fans ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                <div className="flex flex-col justify-between bg-transparent border-2 border-[#3d3d3d] text-center p-2 rounded-lg shadow-lg shadow-[#007B55] w-full h-full hover:scale-105 transition-transform duration-300">
                  <div>
                    <div className="text-sm font-semibold mb-1 text-white">Fans</div>
                    <div className="text-xs text-[#f5f5f5] font-normal mb-1">
                      Purchase authentic, affordable, and memorable tickets. Interact to unlock exclusive rewards.
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <FontAwesomeIcon icon={faArrowRight} className="h-3 w-3 text-white" />
                  </div>
                </div>
              </Link>

              <Link href="/host" passHref className={`w-full transition-all duration-500 ease-in-out ${cardAnimationState.hosts ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                <div className="flex flex-col justify-between bg-transparent border-2 border-[#3d3d3d] text-center p-2 rounded-lg shadow-lg shadow-[#007B55] w-full h-full hover:scale-105 transition-transform duration-300">
                  <div>
                    <div className="text-sm font-semibold mb-1 text-white">Hosts</div>
                    <div className="text-xs text-[#f5f5f5] font-normal mb-1">
                      Empower your events with seamless ticketing, enhanced security, and innovative engagement tools.
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <FontAwesomeIcon icon={faArrowRight} className="h-3 w-3 text-white" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        {/* Desktop cards */}
        <div className={`hidden md:block md:absolute md:bottom-20 md:left-60 w-full py-2 md:py-8 transition-all duration-1000 ${
          animationState === 'revealed' ? 'opacity-100' : 'opacity-0'
        } ${
          isScrolled ? 'fixed top-0 left-0 h-screen flex items-center justify-center' : ''
        }`}>
          <div className={`flex flex-row justify-center items-center gap-5 px-5 max-w-screen-lg mx-auto ${
            isScrolled ? 'h-full' : ''
          }`}>
            
            <Link href="/fan/explore" passHref className={`w-1/3 transition-all duration-500 ease-in-out ${cardAnimationState.fans ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
              <div className="flex flex-col justify-between bg-black text-left p-6 rounded-lg shadow-lg shadow-[#007B55] w-full h-full hover:scale-110 transition-transform duration-300">
                <div>
                  <div className="text-xl lg:text-2xl font-semibold mb-2 text-white">Fans</div>
                  <div className="text-sm text-[#f5f5f5] font-normal mb-4">
                    Purchase authentic, affordable, and memorable tickets. Interact to unlock exclusive rewards.
                  </div>
                </div>
                <div className="flex justify-end">
                  <FontAwesomeIcon icon={faArrowRight} className="h-5 w-5 text-white" />
                </div>
              </div>
            </Link>

            <Link href="/host" passHref className={`w-1/3 transition-all duration-500 ease-in-out ${cardAnimationState.hosts ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
              <div className="flex flex-col justify-between bg-black text-left p-6 rounded-lg shadow-lg shadow-[#007B55] w-full h-full hover:scale-110 transition-transform duration-300">
                <div>
                  <div className="text-xl lg:text-2xl font-semibold mb-2 text-white">Hosts</div>
                  <div className="text-sm text-[#f5f5f5] font-normal mb-4">
                    Empower your events with seamless ticketing, enhanced security, and innovative engagement tools.
                  </div>
                </div>
                <div className="flex justify-end">
                  <FontAwesomeIcon icon={faArrowRight} className="h-5 w-5 text-white" />
                </div>
              </div>
            </Link>

          </div>
        </div>
      </div>

      <style jsx global>{`
        :root {
          --safe-area-top: env(safe-area-inset-top);
          --safe-area-bottom: env(safe-area-inset-bottom);
          --safe-area-left: env(safe-area-inset-left);
          --safe-area-right: env(safe-area-inset-right);
        }

        .top-safe-top {
          top: var(--safe-area-top);
        }

        .pt-safe-top {
          padding-top: var(--safe-area-top);
        }
      `}</style>
    </div>
  );
}