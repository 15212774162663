"use client";

import Head from "next/head";
import Welcome from "@/components/welcome/welcome";
import { useRef, useEffect, useState } from 'react';

export default function Home() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    const playVideo = () => {
      videoElement.play().catch(error => {
        console.log('Autoplay was prevented:', error);
      });
    };

    const handleCanPlayThrough = () => {
      setIsVideoLoaded(true);
      playVideo();
    };

    videoElement.addEventListener('canplaythrough', handleCanPlayThrough);

    const handleInteraction = () => {
      if (isVideoLoaded) {
        playVideo();
      }
    };

    // iOS-specific handling
    const handleLoadedMetadata = () => {
      videoElement.muted = true;
      videoElement.play().then(() => {
        // Unmute if autoplay succeeds
        videoElement.muted = false;
      }).catch(error => {
        console.log('Autoplay was prevented:', error);
      });
    };

    videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);

    // Add these event listeners for iOS
    videoElement.addEventListener('pause', playVideo);
    videoElement.addEventListener('ended', playVideo);

    document.addEventListener('touchstart', handleInteraction, { once: true });
    document.addEventListener('click', handleInteraction, { once: true });

    return () => {
      videoElement.removeEventListener('canplaythrough', handleCanPlayThrough);
      videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      videoElement.removeEventListener('pause', playVideo);
      videoElement.removeEventListener('ended', playVideo);
      document.removeEventListener('touchstart', handleInteraction);
      document.removeEventListener('click', handleInteraction);
    };
  }, [isVideoLoaded]);

  return (
    <>
      <Head>
        <title>Ticket Tree</title>
        <meta property="og:title" content="Ticket Tree" key="title" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
      </Head>

      <main className="relative w-screen h-screen overflow-hidden bg-black">
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src="https://d2hpk16e9jfjkn.cloudfront.net/tick3ttree/fan/FANFOOTAGE_v2.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-70"></div>

        <div className="relative z-10 w-full h-full">
          <Welcome />
        </div>
      </main>

      <style jsx global>{`
        html, body {
          margin: 0;
          padding: 0;
          overflow: hidden;
        }

        body {
          position: fixed;
          width: 100%;
          height: 100%;
          background-color: black;
          padding-top: env(safe-area-inset-top);
        }

        #__next {
          height: 100%;
          width: 100%;
        }

        main {
          height: 100vh;
          width: 100vw;
          padding-top: env(safe-area-inset-top);
        }
      `}</style>
    </>
  );
}