"use client";

import React, { useEffect, useRef } from 'react';
import { rubik } from "@/lib/fonts";
import './WordAnimation.css';

const WordAnimation: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const words = containerRef.current.querySelectorAll(".word");
    words.forEach(word => {
      let letters = word.textContent?.split("");
      word.textContent = "";
      letters?.forEach(letter => {
        let span = document.createElement("span");
        span.textContent = letter;
        span.className = "letter";
        word.append(span);
      });
    });

    let currentWordIndex = 0;
    const maxWordIndex = words.length - 1;
    (words[currentWordIndex] as HTMLElement).style.opacity = "1";

    const rotateText = () => {
      const currentWord = words[currentWordIndex];
      const nextWord = currentWordIndex === maxWordIndex ? words[0] : words[currentWordIndex + 1];
      // rotate out letters of current word
      Array.from(currentWord.children).forEach((letter, i) => {
        setTimeout(() => {
          (letter as HTMLElement).className = "letter out";
        }, i * 80);
      });
      // reveal and rotate in letters of next word
      (nextWord as HTMLElement).style.opacity = "1";
      Array.from(nextWord.children).forEach((letter, i) => {
        (letter as HTMLElement).className = "letter behind";
        setTimeout(() => {
          (letter as HTMLElement).className = "letter in";
        }, 340 + i * 80);
      });
      currentWordIndex = currentWordIndex === maxWordIndex ? 0 : currentWordIndex + 1;
    };

    rotateText();
    const intervalId = setInterval(rotateText, 4000);
    
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={`${rubik.className} rotating-text`} ref={containerRef}>
      <p>Ticket Tree is</p>
      <p className="word-container">
        <span className="word alizarin">safer.</span>
        <span className="word wisteria">faster.</span>
        <span className="word peter-river">cheaper.</span>
        <span className="word emerald">better.</span>
        <span className="word sun-flower">cooler.</span>
      </p>
    </div>
  );
};

export default WordAnimation;